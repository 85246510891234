<template>
  <div class="side-menu">
      <div class="side-menu-container">
        <div class="group">
          <div class="icon basic-components" @click="primaryItemsDialog = !primaryItemsDialog">
              <dialog-primary-items
                v-on:primary-element-changed="$emit('insert')"
              ></dialog-primary-items>
          </div>
          <div class="selected-primary-item-color-picker">
            <div
              v-for="primaryItemVariation in selectedPrimaryItem.variations"
              :key="'primaryItemVariation_'+primaryItemVariation.id"
              :class="['variation-color', primaryItemVariation.color, (primaryItemVariation.color == selectedPrimaryItemVariation.color ? 'selected' : '')]"
              @click="updateToPrimaryItem(selectedPrimaryItem, primaryItemVariation)"
            ></div>
          </div>
        </div>
        <div class="group" v-on:click="$emit('mass-insert')">
          <div class="icon mass-insert"></div>
          <div class="center-text">
            Multi drop
          </div>
        </div>
        <div class="group">
          <div class="icon accessories" @click="accessoriesDialog = !accessoriesDialog">
            <dialog-accessories
              v-on:accessory-changed="$emit('insert')"
            ></dialog-accessories>
          </div>
          <div class="selected-accessory-color-picker" v-if="selectedAccessory">
            <div
              v-for="accessoryVariation in selectedAccessory.variations"
              :key="'accessoryVariation_'+accessoryVariation.id"
              :class="['variation-color', accessoryVariation.color, (accessoryVariation.color == selectedAccessoryVariation.color ? 'selected' : '')]"
              @click="updateToAccessory(selectedAccessory, accessoryVariation)"
            ></div>
          </div>
        </div>
        <div class="group" v-on:click="$emit('rotate')">
          <div class="icon rotate"></div>
          <div class="center-text">
              {{ $t(`configurator.rotate`) }}
          </div>
        </div>

        <div class="group" v-on:click="$emit('delete')">
          <div class="icon remove"></div>
          <div class="center-text">
              {{ $t(`configurator.remove.single`) }}
          </div>
        </div>

        <div class="group" v-on:click="enterTextDialog = !enterTextDialog">
          <div class="icon text"></div>
          <div class="center-text">
            {{ $t(`configurator.enterText.dialog.title`) }}
          </div>
        </div>

        <div class="group" v-on:click="deleteAllDialog = !deleteAllDialog">
          <div class="icon remove-all"></div>
          <div class="center-text">
            {{ $t(`configurator.remove.all`) }}
          </div>
        </div>
        
      </div>
      <v-dialog
        v-model="deleteAllDialog"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 lighten-2 card-title-dialog-warning">
            {{ $t(`configurator.remove.dialog.title`) }}
          </v-card-title>
  
          <v-card-text class="dialog-text">
            {{ $t(`configurator.remove.dialog.content`) }}
          </v-card-text>

          <v-divider></v-divider>
  
          <v-card-actions>
            <v-btn
              dark
              color="#004996"
              depressed
              @click="deleteAllDialog = !deleteAllDialog"
            >
              {{ $t(`configurator.remove.dialog.controls.no`) }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="#b00d0d"
              depressed
              @click="emitDeleteAll()"
            >
              {{ $t(`configurator.remove.dialog.controls.yes`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="enterTextDialog"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 lighten-2 card-title-dialog">
            {{ $t(`configurator.enterText.dialog.title`) }}
          </v-card-title>

          <v-card-text class="dialog-text">
            {{ $t(`configurator.enterText.dialog.content`) }}
            <v-text-field 
              :placeholder="$t(`configurator.enterText.dialog.title`)" 
              v-model="gridText" 
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>
  
          <v-card-actions>   
            <v-btn
              dark
              color="#b00d0d"
              depressed
              @click="enterTextDialog = !enterTextDialog"
            >
              {{ $t(`configurator.enterText.dialog.controls.discard`) }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="#004996"
              depressed
              @click="enterTextConfirm()"
            >
              {{ $t(`configurator.enterText.dialog.controls.confirm`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import DialogPrimaryItems from './DialogPrimaryItems.vue'
import DialogAccessories from './DialogAccessories.vue'

export default {
  name: 'SideMenu',

  components: {
    DialogPrimaryItems,
    DialogAccessories
  },

  data () {
    return {
      gridText: "",
      deleteAllDialog: false,
      enterTextDialog: false,
    }
  },

  watch: {
    gridText: function (value) {
      this.$emit('change-text', value)
    }
  },

  methods: {
    updateToPrimaryItem(primaryItem, primaryItemVariation) {
      // update primary item becouse of currentItemType
      this.selectedPrimaryItem = primaryItem
      this.selectedPrimaryItemVariation = primaryItemVariation
      this.$emit('insert')
    },
    updateToAccessory(accessory, accessoryVariation) {
      // update accessory becouse of currentItemType
      this.selectedAccessory = accessory
      this.selectedAccessoryVariation = accessoryVariation
      this.$emit('insert')
    },
    emitDeleteAll() {
      this.$emit('clear-all');
      this.deleteAllDialog = false
    },
    enterTextConfirm() {
      this.$emit('insert-text');
      this.enterTextDialog = false
    }
  },
  
  computed: {
    primaryItemsDialog: {
      get() {
        return this.$store.getters['configurator/getPrimaryItemsDialog'];
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemsDialog', newValue)
      }
    },
    accessoriesDialog: {
      get() {
        return this.$store.getters['configurator/getAccessoriesDialog'];
      },
      set(newValue) {
        this.$store.commit('configurator/setAccessoriesDialog', newValue)
      }
    },
    selectedPrimaryItem: {
      get() {
        let storedItem = this.$store.getters['configurator/getPrimaryItem']

        if (storedItem) return storedItem

        return null
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItem', newValue) //store local item to store
      }
    },
    selectedPrimaryItemVariation: {
      get() {
        let storedVariation = this.$store.getters['configurator/getPrimaryItemVariation']
        
        if (storedVariation) return storedVariation

        //no variation is stored so selected the first one
        return null
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemVariation', newValue)
      }
    },
    selectedAccessory: {
      get() {
        return this.$store.getters['configurator/getAccessory']
      },
      set(newValue) {
        this.$store.commit('configurator/setAccessory', newValue) //store local item to store
      }
    },
    selectedAccessoryVariation: {
      get() {
        return this.$store.getters['configurator/getAccessoryVariation']
      },
      set(newValue) {
        this.$store.commit('configurator/setAccessoryVariation', newValue)
      }
    },
  }
}
</script>
