<template>
  <div class="configurator full-height">
    <v-container class="full-height configurator-container" fluid>
      <v-row class="button-row mobile-reverse">
        <v-col cols="12" md="6" order="1">
          <level-picker></level-picker>
        </v-col>
        <v-col cols="12" md="6"  order="12" class="button-container">
            <div class="button-back">
              <router-link :to="{ name: 'basic-data' }" class="plain-link">
                <v-icon size="43" color="black"> mdi-chevron-left</v-icon>
                <span class="button-back-text">
                  {{ $t(`general.navigation.buttons.back`) }}
                </span>
              </router-link>
            </div>
          <v-divider
            class="mx-4 invisible-divider"
            vertical
          ></v-divider>
          <div class="button-forward">
            <a v-on:click="nextStepDialog = !nextStepDialog" class="plain-link">
              <span class="button-forward-text">
                {{ $t(`general.navigation.buttons.next`) }}
              </span>
              <v-icon size="43" color="white">mdi-chevron-right</v-icon>
            </a>
          </div>
        </v-col>
      </v-row>
      <v-row class="screen-sized">
        <side-menu 
          v-on:clear-all="clearAll()"
          v-on:rotate="rotate()"
          v-on:insert="mode='insert'"
          v-on:insert-text="mode='insert_text'"
          v-on:delete="mode='delete'"
          v-on:mass-insert="mode='mass_insert'"
          v-on:change-text="(value) => gridText = value"
        ></side-menu>
        <div>
           {{items}}
        </div>
        <div ref="pixiCanvasWrapper" class="configurator-canvas pixiBody">
            <canvas ref="pixiCanvas" id="pixiCanvas"></canvas>
        </div>
      </v-row>
    </v-container>
    <v-dialog
        v-model="nextStepDialog"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 lighten-2 card-title-dialog">
            {{ $t(`configurator.nextStep.dialog.title`) }}
          </v-card-title>
  
          <v-card-text class="dialog-text">
            {{ $t(`configurator.nextStep.dialog.content`) }}
          </v-card-text>

          <v-divider></v-divider>
  
          <v-card-actions class="display-buttons-verticaly">
            <v-btn
              dark
              color="#004996"
              depressed
              @click="nextStepDialog = !nextStepDialog"
            >
              {{ $t(`configurator.nextStep.dialog.controls.no`) }}
            </v-btn>
            <v-spacer class="divider-margin-small"></v-spacer>
            <v-btn
              color="success"
              depressed
              @click="nextStage()"
            >
              {{ $t(`configurator.nextStep.dialog.controls.yes`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */

import _ from 'lodash';
import SideMenu from '../components/configurator/SideMenu.vue'
import LevelPicker from '../components/configurator/LevelPicker.vue'
import * as PIXI from "pixi.js";
import { Viewport } from "pixi-viewport";
import { EventSystem } from "@pixi/events";
import GridPoint from "@/components/configurator/Editor/lib/Cordinates/GridPoint";
import ElementStore from "@/components/configurator/Editor/lib/ElementStore";
import TextStore from "@/components/configurator/Editor/lib/TextStore";
import { mapGetters } from 'vuex';
import store from '../plugins/store/vuex';
import router from '@/plugins/router';
import constants from "@/components/configurator/Editor/lib/constants.js";
import Helpers from "@/components/configurator/Editor/lib/Helpers.js"

//Images for configurator
import removeIcon from "@/assets/images/configurator/sprites/remove-icon.png";
import textIcon from "@/assets/images/configurator/sprites/text-icon.png";
import beachBackgroundTop from "@/assets/images/configurator/sprites/background/top.png";
import beachBackgroundMiddle from "@/assets/images/configurator/sprites/background/middle.jpg";
import beachBackgroundBottom from "@/assets/images/configurator/sprites/background/bottom.png";


export default {
  components: {
    SideMenu,
    LevelPicker,
  },
  name: 'configurator-v3',
  ready: function () {},
  data() {
    return {
      mode: constants.MODES.INSERT,
      insertColor: "black",
      elementSelected: null,
      gridText: '',
      canDrop: true,
      currentRotation: 0,
      itemList: {},
      items: {},
      nextStepDialog: false,
      floatingElement: new PIXI.Sprite(), //Must be globaly accessible becouse it changes on mode and current item change
      floatingElementPosition: new GridPoint(0,0,0),
      massInsert: {
        startPoint: null,
        endPoint: null,
        draw: {
          startPoint: null,
          isDrawing: false,
          element: new PIXI.Graphics()
        }
      },
      pixiLevels: {
        0: new PIXI.Container(),
        1: new PIXI.Container(),
        2: new PIXI.Container(),
        3: new PIXI.Container(),
        4: new PIXI.Container()
      },
      pixiApp: null,
      configuration: null,
    };
  },
  computed: {
    ...mapGetters({
      projectReferenceId: 'projectData/getRefNumber',
      currentItem: 'configurator/getCurrentItem',
      currentLevel: 'configurator/getLevel',
      nLines: 'configurator/getNLines',
      boxSize: 'configurator/getBoxSize',
    })
  },
  watch: {
    currentItem: function (newValue, oldValue) {
      // When currentItem updates, update floating element sprite
      this.setupFloatingElement();
    },
    mode: function (newValue, oldValue) {
      // When mode updates, update floating element sprite
      this.setupFloatingElement();
    },
    currentLevel: function(newValue, oldValue) {
      //console.log(newValue);
      this.pixiLevels[newValue].alpha = 1;
      this.pixiLevels[newValue].zIndex = 99;
      for (let i = 0; i <= 4; i++) {
        if (i != newValue) {
          this.pixiLevels[i].alpha = 0.3;
          this.pixiLevels[i].zIndex = 0;
        }
      }
    }
  },
  created() {
    this.helpers = new Helpers();
    this.elementStore = new ElementStore();
    this.textStore = new TextStore();
  },
  mounted() {
    console.log("Starting PIXI application");

    delete PIXI.Renderer.__plugins.interaction;

    let canvas = this.$refs["pixiCanvas"];
    let canvasWrapper = this.$refs["pixiCanvasWrapper"];

    this.pixiApp = new PIXI.Application({
      width: canvasWrapper.clientWidth, 
      height: canvasWrapper.clientHeight,
      //width: 800,
      //height: 600,
      backgroundColor: "0xFFFFFF",
      view: canvas,
      // resizeTo: canvasWrapper,
      preserveDrawingBuffer: true,
      resolution: window.devicePixelRatio || 1
    });

    if (!('events' in this.pixiApp.renderer)) {
      this.pixiApp.renderer.addSystem(EventSystem, 'events');
    }

    let pixiViewport = new Viewport({
      worldHeight: this.nLines * this.boxSize,
      worldWidth: this.nLines * this.boxSize,
      screenWidth: canvasWrapper.clientWidth, 
      screenHeight: canvasWrapper.clientHeight,
      divWheel: canvas,
      passiveWheel: false //this prevent scroling of page on scroll wheel TODO: Check if it breaks something 
    })

    pixiViewport
      .setZoom(1.1)
      .drag()
      // Drag event fires click event of pixiApp so while user is dragign prevent elements droping
      .on('drag-start', (screen, word) => {
        // console.log("Drag started")
        this.canDrop = false
      })
      .on('drag-end', (screen, word) => {
        // console.log("Drag ended")
        setTimeout(() => { this.canDrop = true }, 500);
      })
      .pinch()
      .wheel()
      .decelerate()
      .clamp({ direction: 'all' })
      .clampZoom({ minScale: 0.3, maxScale: 1.5 })
      .moveCenter((this.nLines * this.boxSize) / 2, (this.nLines * this.boxSize) / 2);
    
    // Enable this so we can use zIdex on sprites
    pixiViewport.sortableChildren = true;

    this.pixiApp.stage.addChild(pixiViewport);

    let loadingElement = this.setupLoadingElement();
    pixiViewport.addChild(loadingElement);

    let inventoryElements = this.$store.getters['api/getInventory'];
    let loader = new PIXI.Loader();

    inventoryElements.forEach((element) => {
      element.variations.forEach((variation) => {
        loader.add(element.name + "-" + variation.color, `${process.env.VUE_APP_BACKEND_STORAGE_URL}/${variation.canvas_image}`, {
            crossOrigin: "*"
        });
      });
    });

    // Static assets
    loader.add('remove-icon', removeIcon);
    loader.add('text-icon', textIcon);
    loader.add('beach-background-top', beachBackgroundTop);
    loader.add('beach-background-middle', beachBackgroundMiddle);
    loader.add('beach-background-bottom', beachBackgroundBottom);

    // Create row numbers - its outside load function becouse we need it to be visible for ticker
    let rowNumbersElement = this.setupRowNumbersElement()

    // Create colum numbers - its outside load function becouse we need it to be visible for ticker
    let colNumbersElement = this.setupColNumbersElement()
    
    loader.load(() => {
      /**
       * Create static assets 
       * 
       * NOTE: Be careful in what order do we place elements as it affect zindex of drawing 
       * Example: If you setup level viewports before grid element. Whenever you draw on level container grid will be drawn over it.
       * 
       * Create instance of floating element and add it to vieport it will change texture and position in the ticker
       * This has to be here or we get an error https://github.com/pixijs/pixijs/issues/6615 becouse the assets are not ready for some reason
       */ 
      this.setupFloatingElement()
      pixiViewport.addChild(this.floatingElement);

      // Backgound images
      this.setupBackgroundImages(pixiViewport);

      // Create and add grid
      let gridElement = this.setupGridElement();
      pixiViewport.addChild(gridElement);

      // Create level placeholders
      pixiViewport.addChild(this.pixiLevels[0]);
      pixiViewport.addChild(this.pixiLevels[1]);
      pixiViewport.addChild(this.pixiLevels[2]);
      pixiViewport.addChild(this.pixiLevels[3]);
      pixiViewport.addChild(this.pixiLevels[4]);

      // Add row numbers
      pixiViewport.addChild(rowNumbersElement);

      // Add colum numbers
      pixiViewport.addChild(colNumbersElement);

      loadingElement.visible = false;

      // load already saved project
      let loadedProject = this.projectReferenceId != null;
      if (loadedProject) {
        let loadedProjectConfiguration = this.$store.getters['projectData/getConfiguration'];
        // load base elements
        for (let element of loadedProjectConfiguration["elements"]) {
          // TODO put this into common function because the same is done in configurator-v2/src/plugins/store/modules/configurator.js:73
          // TODO check if this works
          let item = inventoryElements.filter(item => item.name == element.itemName)[0];
          let gridPosition = new GridPoint(element.gridPosition.x, element.gridPosition.y, element.gridPosition.level);
          let mappedItem = {
            "size": constants.ELEMENT_SIZES[item.rule],
            "availableConnections": constants.ELEMENT_CONNECTIONS[item.rule],
            "item": item,
            "variation": item.variations.filter(item => item.color == element.color)[0]
          };
          this.handleInsert(gridPosition, mappedItem, this.elementStore, this.pixiLevels[element.gridPosition.level]);
        }

        // load texts
        for (let element of loadedProjectConfiguration["texts"]) {
          let gridPosition = new GridPoint(element.gridPosition.x, element.gridPosition.y, element.gridPosition.level);
          this.handleTextInsert(gridPosition, element.text, this.textStore, this.pixiLevels[element.gridPosition.level]);
        }

        // TODO load connectors -> a rabimo sej jih bo handle single drop not nametal 
      }

      // Use pointerdown/pointerup for mobile users (click is mouse event)
      this.pixiApp.stage.addEventListener('pointerdown', (event) => { 
        if(!this.canDrop) return;

        const mousePos = pixiViewport.toWorld(event.data.global);
        const gridPosition = this.getGridCoordinates(mousePos);
        // Za handle delete bi bilo nekak optimalno če pogledamo da je bilo kliknjeno na sredini k zdej moraš klikat tko mal wonky da prime 
        // pa se spomnim da si mel to narejeno za prejšno verzijo konfiguratorja

        switch(this.mode) {
          case constants.MODES.DELETE: {
            this.handleDelete(gridPosition, this.elementStore, this.pixiLevels[this.currentLevel], this.textStore)
            break;  
          }

          case constants.MODES.INSERT_TEXT: {
            this.handleTextInsert(gridPosition, this.gridText, this.textStore, this.pixiLevels[this.currentLevel])
            break; 
          }

          case constants.MODES.MASS_INSERT: {
            pixiViewport.plugins.pause('drag');
            this.massInsert.draw.isDrawing = true;
            this.massInsert.startPoint = gridPosition;
            this.massInsert.draw.startPoint = mousePos;    
            break; 
          }
        }
        this.items = this.elementStore.getItems();
        this.itemList = this.elementStore.getItemList();
      });

      this.pixiApp.stage.addEventListener('pointerup', (event) => {
        if(!this.canDrop) return;

        const mousePos = pixiViewport.toWorld(event.data.global);
        const gridPosition = this.getGridCoordinates(mousePos);

        switch(this.mode) {
          case constants.MODES.INSERT: { // Insert is handled on pointerup becouse of pointerdown happens before drag disables ability to drop
            this.handleInsert(gridPosition, this.currentItem, this.elementStore, this.pixiLevels[this.currentLevel]);
            break;
          }

          case constants.MODES.MASS_INSERT: {
            pixiViewport.plugins.resume('drag');
            this.massInsert.draw.isDrawing = false;
            this.massInsert.endPoint = gridPosition;
            this.handleMassInsert(this.pixiLevels[this.currentLevel], this.elementStore);
            if(this.massInsert.draw.element._geometry != null) {
              this.massInsert.draw.element.destroy()
            }
            
            break;
          }
        }
        this.items = this.elementStore.getItems();
        this.itemList = this.elementStore.getItemList();
      });

      // When mouse moves over pixi app update floating element & if we are in mass insert mode redraw square
      this.pixiApp.stage.addEventListener('mousemove', (event) => {
        const mousePos = pixiViewport.toWorld(event.data.global);
        
        this.updateFloatingElementPosition(mousePos)

        switch(this.mode) {
          case constants.MODES.MASS_INSERT: {
            this.redrawMassInsertRectangle(this.pixiLevels[this.currentLevel], mousePos);
            break;
          }
        }
      });

      // When mouse enters the pixi app start drawing floatin element
      this.pixiApp.stage.addEventListener('mouseenter', (event) => {
        this.floatingElement.visible = (this.mode == constants.MODES.MASS_INSERT ? false : true);
      });

      // When mouse leaves the pixi app stop drawing floatin element
      this.pixiApp.stage.addEventListener('mouseleave', (event) => {
        this.floatingElement.visible = false;
      }); 

      // Check if user preselected any shapes and fill the grid with them
      this.setupBasicShapeConfiguration(this.pixiLevels[this.currentLevel], this.elementStore);
    })

        // Allow mass delete when user presses escape button
    window.addEventListener("keyup", (event) => {
      if(event.key === 'Escape') {
        this.clearLevels();
        this.elementStore.removeAllElements();
        this.textStore.removeAllTexts();
      }

      if(event.key === 'Enter') {
        console.log(this.elementStore.elements)
        console.log(this.elementStore.connectors)
        console.log(this.elementStore.underlayedElements)
        console.log(this.elementStore.overlayedElements)
      }
    });

    this.pixiApp.ticker.add((delta) => {
      loadingElement.rotation += 0.01 * delta;

      // Draw floating element each tick based on mode and current element
      this.redrawFloatingElement();

      // Redraw vertical and horizontal numbers so they "follow" the user
      this.redrawGridRowAndColNumbers(rowNumbersElement, colNumbersElement, pixiViewport)
    });
  },
  methods: {
    setupBackgroundImages(pixiViewport) {
      let worldDimensions = this.nLines * this.boxSize;
      let middleImageHeight = 450; // Sprite height

      const topTexture = PIXI.Texture.from('beach-background-top');
      const topTilingSprite = new PIXI.TilingSprite(topTexture, worldDimensions, (worldDimensions / 2) - (middleImageHeight / 2));
      topTilingSprite.alpha = 0.4;
      pixiViewport.addChild(topTilingSprite);

      const bottomTexture = PIXI.Texture.from('beach-background-bottom');
      const bottomTilingSprite = new PIXI.TilingSprite(bottomTexture, worldDimensions, worldDimensions / 2);
      bottomTilingSprite.alpha = 0.4;
      bottomTilingSprite.position.set(0, (worldDimensions / 2) + (middleImageHeight / 2));
      pixiViewport.addChild(bottomTilingSprite);

      const middleTexture = PIXI.Texture.from('beach-background-middle');
      const middleTilingSprite = new PIXI.TilingSprite(middleTexture, worldDimensions, middleImageHeight);
      middleTilingSprite.alpha = 0.4;
      middleTilingSprite.position.set(0, (worldDimensions / 2) - (middleImageHeight / 2));
      pixiViewport.addChild(middleTilingSprite);
    },
    setupGridElement() {
      let gridElement = new PIXI.Container();
      let line = new PIXI.Graphics();
      line.lineStyle(4, 0xb5aeae, 0.5);

      // Vertical lines
      let verticalLines = new PIXI.Container();
      for (var rowNumber = 0; rowNumber <= this.nLines; rowNumber++) {
        line.moveTo(rowNumber * this.boxSize, 0);
        line.lineTo(rowNumber * this.boxSize, this.nLines * this.boxSize);
        verticalLines.addChild(line);
      }
      gridElement.addChild(verticalLines);

      // Horizontal lines
      let horizontalLines = new PIXI.Container();
      for (var colNumber = 0; colNumber <= this.nLines; colNumber++) {
        line.moveTo(0, colNumber * this.boxSize);
        line.lineTo(this.nLines * this.boxSize, colNumber * this.boxSize);
        horizontalLines.addChild(line);
      }
      gridElement.addChild(horizontalLines);  

      return gridElement;
    },
    setupLoadingElement() {
      let loadingElement = new PIXI.Text("Loading ...", {
        fontFamily: "Arial",
        fontSize: 24,
        fontWeight: "bold",
        align: "center",
      });

      loadingElement.pivot.set(loadingElement.width / 2, loadingElement.height / 2)
      loadingElement.position.set((this.nLines * this.boxSize) / 2, (this.nLines * this.boxSize) / 2);

      return loadingElement;
    },
    setupFloatingElement() {
      this.floatingElement.zIndex = 100;
      switch(this.mode) {
        case constants.MODES.INSERT: {
          // For insert mode get current selected element texture name & make it a bit transaprent
          this.floatingElement.texture = PIXI.Texture.from(
            this.getPixiTextureName()
          );

          this.floatingElement.width = this.currentItem.item.size.width * this.boxSize;
          this.floatingElement.height = this.currentItem.item.size.height * this.boxSize;

          this.floatingElement.alpha = 0.7;
          this.floatingElement.visible = false;

          break;
        }

        case constants.MODES.DELETE: {
          // For delete mode draw remove icon & remove transaprency
          this.floatingElement.texture = PIXI.Texture.from(
            'remove-icon'
          );

          this.floatingElement.height = 20;
          this.floatingElement.width = 20;
          this.floatingElement.alpha = 1;
          this.floatingElement.visible = false;

          break;  
        }

        case constants.MODES.INSERT_TEXT: {
          this.floatingElement.texture = PIXI.Texture.from(
            'text-icon'
          );

          // Set floating element width and height to match sprite
          this.floatingElement.height = 43;
          this.floatingElement.width = 243;
          this.floatingElement.alpha = 1;
          this.floatingElement.visible = false;

          break; 
        }

        case constants.MODES.MASS_INSERT: {
          this.floatingElement.visible = false;
          break;
        }
      } 
    },
    setupRowNumbersElement() {
      let rowNumbersElement = new PIXI.Container();

      for (var rowNumber = 0; rowNumber <= this.nLines; rowNumber++) {
        let rowTextElement = this.setupRowNumberElement(rowNumber);
        rowNumbersElement.addChild(rowTextElement)
      }

      return rowNumbersElement;
    },
    setupColNumbersElement() {
      let colNumbersElement = new PIXI.Container();

      for (var colNumber = 0; colNumber <= this.nLines; colNumber++) {
        let colTextElement = this.setupColNumberElement(colNumber);
        colNumbersElement.addChild(colTextElement)
      }

      return colNumbersElement;
    },
    setupRowNumberElement(rowNumber) {
      // Calculate text for text element
      let rowText = "";

      if (rowNumber > (this.nLines / 2)) {
        rowText = Math.abs(this.nLines / 4 - rowNumber*0.5);
        if (rowNumber % 10 == 0 && rowText != 0) rowText = "R" + rowText;
      } else {
        rowText = Math.abs(rowNumber*0.5 - this.nLines / 4);
        if (rowNumber % 10 == 0 && rowText != 0) rowText = "L" + rowText;
      }

      let rowTextElement = new PIXI.Text(rowNumber, { // change back to rowText
        fontFamily: "Arial",
        fontSize: 24,
        fontWeight: "bold",
        align: "center",
      });


      rowTextElement.anchor.set(0.5, 0);
      rowTextElement.position.set(rowNumber * this.boxSize, 0);
      return rowTextElement;
    },
    setupColNumberElement(colNumber) {
      // Calculate text for text element
      let colText = "";

      if (colNumber > (this.nLines / 2)) {
        colText = Math.abs(this.nLines / 4 - colNumber*0.5);
        if (colNumber % 10 == 0 && colText != 0) colText = "-" + colText;
      } else {
        colText = Math.abs(colNumber*0.5 - this.nLines / 4);
        if (colNumber % 10 == 0 && colText != 0) colText = "+" + colText;
      }
      let colTextElement = new PIXI.Text(colNumber, { // change back to colText
        fontFamily: "Arial",
        fontSize: 24,
        fontWeight: "bold",
        align: "center",
      });

      colTextElement.position.set(0, colNumber * this.boxSize -12);

      return colTextElement;
    },
    getGridCoordinates(point) {
      return new GridPoint(
        Math.floor(point.x / this.boxSize),
        Math.floor(point.y / this.boxSize),
        this.currentLevel
      );
    },
    createPixiElement(gridPosition, elementName, color) {
      let pixiElement = PIXI.Sprite.from(`${elementName}-${color}`);
      pixiElement.name = this.getElementName(gridPosition, elementName);
      
      pixiElement.position.set(
        gridPosition.x * this.boxSize, 
        gridPosition.y * this.boxSize
      );
      
      return pixiElement;
    },
    drawPixiElement(parent, element) {
      parent.addChild(element);
    },
    deletePixiElement(parent, name) {
      let element = parent.getChildByName(name)
      parent.removeChild(element);
    },
    getElementName(gridPosition, elementName) {
      return `${gridPosition.toString()}-${elementName}`;
    },
    handleInsert(gridPosition, element, elementStore, pixiParent) {
      
      /**
       * Special case when adding connectors 
      */
      if (element.item.group == "connectors") {
        let oldConnector = elementStore.getConnector(gridPosition);
        let connector = elementStore.addConnector(gridPosition, element, oldConnector);
        
        // Where null is returned do not do anything
        if(connector) {
          // Remove odl connector
          if (oldConnector) {
            this.deletePixiElement(pixiParent, this.getElementName(oldConnector.gridPosition, oldConnector.item.name));
          }

          // Draw new connector
          this.drawConnector(connector, pixiParent);
        }
 
        return;
      }

      let [storeElement, connectors] = elementStore.addElement(gridPosition, element, this.currentRotation);
      if (!storeElement) {
        return;
      }

      /**
       * Draw the element we are curently trying to drop
       *
       * Elements that are acting like connectors do not need to be drawn like element
      */
      if (!this.helpers.isOuterScrewJoint(element.item.rule) && !this.helpers.isInnerScrewJoint(element.item.rule) && !this.helpers.isWinch(element.item.rule)) {
        let pixiElement = this.createPixiElement(storeElement.gridPosition, storeElement.item.name, storeElement.variation.color);
        this.helpers.offsetPixiElement(pixiElement, this.currentRotation, this.boxSize, storeElement.item.rule, storeElement.item.size.width, storeElement.item.size.height)

        this.drawPixiElement(pixiParent, pixiElement);
      }

      /**
       * Draw connectors of the element
       * 
       * At this point global array of connectors in the storeis already updated so 
       * we dont know the old connector name so we have to guess it
       */
      let defaultSideScrew = store.getters['api/getInventoryDefaultSideScrew'];
      let defaultConnectingPin = store.getters['api/getInventoryDefaultConnectorsConnectingPin'];

      for(let connector of connectors) {
        this.deleteOldConnectorFromCanvas(pixiParent, connector.gridPosition, defaultSideScrew, defaultConnectingPin)
        
        // If connector is a placeholder we do not redraw
        if (connector.placeholder) continue;

        // If connector is a fence we must draw it as a element (fences are not isngle point elements like connecotrs)
        if (this.helpers.isFence(element.item.rule)) continue;

        this.drawConnector(connector, pixiParent);
      }
    },
    handleMassInsert(pixiParent, elementStore) {
      let startX = (this.massInsert.startPoint.x < this.massInsert.endPoint.x ? this.massInsert.startPoint.x : this.massInsert.endPoint.x)
      let endX = (this.massInsert.startPoint.x > this.massInsert.endPoint.x ? this.massInsert.startPoint.x : this.massInsert.endPoint.x)

      let startY = (this.massInsert.startPoint.y < this.massInsert.endPoint.y ? this.massInsert.startPoint.y : this.massInsert.endPoint.y)
      let endY = (this.massInsert.startPoint.y > this.massInsert.endPoint.y ? this.massInsert.startPoint.y : this.massInsert.endPoint.y)

      let points = [];

      for(let currentX = startX; currentX <= endX; currentX++) {
        for(let currentY = startY; currentY <= endY; currentY++) {
          //console.log("Point("+currentX+","+currentY+")")
          points.push(new GridPoint(currentX, currentY, this.currentLevel));
        }
      }

      points.forEach((gridPosition) => {
        this.handleInsert(gridPosition, this.currentItem, elementStore, pixiParent)
      });
    },
    handleDelete(gridPosition, elementStore, pixiParent, textStore) {
      //Check if some texts need to be removed
      let removedTextElements = textStore.removeText(gridPosition)
      if (removedTextElements.length != 0) {
        removedTextElements.forEach((name) => {
          this.deletePixiElement(pixiParent, name);
        })
        return;
      }

      // Check for connector elements first 
      let connector = elementStore.getConnector(gridPosition)
      if(connector && (this.helpers.isOuterScrewJoint(connector.item.rule) || this.helpers.isInnerScrewJoint(connector.item.rule))) {
        
        let [redrawedConnectors, removedConnectors] = elementStore.removeConnector(connector)

        for(let removeConnector of removedConnectors) {
          this.deletePixiElement(pixiParent, this.getElementName(removeConnector.gridPosition, removeConnector.item.name));  
        }

        for(let redrawedConnector of redrawedConnectors) {
          this.deletePixiElement(pixiParent, this.getElementName(redrawedConnector.gridPosition, redrawedConnector.item.name));
          this.drawConnector(redrawedConnector, pixiParent);
        }
        
        // Connector element found delete it and skip everything else 
        return
      }

      //Check if elements must be removed 
      let storedElement = elementStore.getOverlayedElement(gridPosition);
      if (!storedElement) storedElement = elementStore.getElement(gridPosition)

      if (!storedElement) return

      let [redrawedConnectors, removedConnectors, removedOverlayedElements, removedUnderlayingElements, removedElements] = elementStore.removeElement(storedElement);
      console.log("redrawedConnectors")
      console.log(redrawedConnectors)

      console.log("removedConnectors")
      console.log(removedConnectors)

      console.log("removedOverlayedElements")
      console.log(removedOverlayedElements)

      console.log("removedUnderlayingElements")
      console.log(removedUnderlayingElements)

      console.log("removedElements")
      console.log(removedElements)

  
      for(let removedElement of removedElements) {
        this.deletePixiElement(pixiParent, this.getElementName(removedElement.gridPosition, removedElement.item.name));  
      }

      for(let removedUnderlayingElement of removedUnderlayingElements) {
        this.deletePixiElement(pixiParent, this.getElementName(removedUnderlayingElement.gridPosition, removedUnderlayingElement.item.name));  
      }
      
      for(let removedOverlayedElement of removedOverlayedElements) {
        this.deletePixiElement(pixiParent, this.getElementName(removedOverlayedElement.gridPosition, removedOverlayedElement.item.name));  
      }

      for(let removeConnector of removedConnectors) {
        this.deletePixiElement(pixiParent, this.getElementName(removeConnector.gridPosition, removeConnector.item.name));  
      }

      for(let redrawedConnector of redrawedConnectors) {
        this.deletePixiElement(pixiParent, this.getElementName(redrawedConnector.gridPosition, redrawedConnector.oldName));
        this.drawConnector(redrawedConnector, pixiParent);
      }

      this.deletePixiElement(pixiParent, this.getElementName(storedElement.gridPosition, storedElement.item.name));
    },
    clearAll() {
      //Clear the store on mount => we added event listner for escape button press 
      window.dispatchEvent(new KeyboardEvent('keyup',{'key':'Escape'})); // We can replace escape with any string we want for custom key bindings
    },
    clearLevels() {
      // Clear all children from levels containers
      for (let key in this.pixiLevels) {
        this.pixiLevels[key].removeChildren();
      }
    },
    handleTextInsert(gridPosition, text, textStore, pixiParent) {
      var textElement = new PIXI.Text(text, {
        fontSize: 20,
        align: "center",
      });

      textElement.interactive = true;
      textElement.buttonMode = true;

      textElement.height = 50;

      textElement.position.set(
        gridPosition.x * this.boxSize, 
        gridPosition.y * this.boxSize 
      );

      // Store text element to store
      let name = textStore.storeText(gridPosition, text, textElement.width, this.boxSize);
      textElement.name = name; // Function returns name for this element and his children, name is used when removing text element 

      this.drawPixiElement(pixiParent, textElement);
    },
    drawConnector(connector, pixiParent) {
      let connectorPixiElement = this.createPixiElement(connector.gridPosition, connector.item.name, connector.color);
      connectorPixiElement.name = this.getElementName(connector.gridPosition, connector.item.name)
      connectorPixiElement.height = 30;
      connectorPixiElement.width = 30;
      connectorPixiElement.position.x -= 15;
      connectorPixiElement.position.y -= 15;
      this.drawPixiElement(pixiParent, connectorPixiElement);
    },
    deleteOldConnectorFromCanvas(pixiParent, gridPosition, defaultSideScrew, defaultConnectingPin) {
      // We dont know what name the old connector has so we just guess all the options
      this.deletePixiElement(pixiParent, this.getElementName(gridPosition, defaultSideScrew.name));
      this.deletePixiElement(pixiParent, this.getElementName(gridPosition, defaultConnectingPin.name));
    },
    getPixiTextureName() {
      return this.currentItem.item.name + '-' + this.currentItem.variation.color
    },
    updateFloatingElementPosition(mousePos) {
      // Depending on the mode we setup cooridnates diffrently
      switch(this.mode) {
        case constants.MODES.INSERT: {
          this.floatingElementPosition = this.getGridCoordinates(mousePos);
          break;
        }

        case constants.MODES.DELETE: {
          this.floatingElementPosition = new GridPoint(mousePos.x, mousePos.y);
          break;  
        }

        case constants.MODES.INSERT_TEXT: {
           this.floatingElementPosition = this.getGridCoordinates(mousePos);
          break; 
        }
      }
    },
    redrawFloatingElement() { 
      switch(this.mode) {
          case constants.MODES.INSERT: {
            this.floatingElement.position.set(
              this.floatingElementPosition.x * this.boxSize,
              this.floatingElementPosition.y * this.boxSize
            )

            // offset groups of elements so they fit to grid (has to be in sync with floating element)
            this.helpers.offsetPixiElement(this.floatingElement, this.currentRotation, this.boxSize, this.currentItem.item.rule)
            
            break;
          }

          case constants.MODES.DELETE: {
            // When drawing remove cross positions are not mapped to grid
            this.floatingElement.position.set(
              (this.floatingElementPosition.x) - (this.floatingElement.width / 2),
              (this.floatingElementPosition.y) - (this.floatingElement.height / 2)
            )

            break;  
          }

          case constants.MODES.INSERT_TEXT: {
            this.floatingElement.position.set(
              (this.floatingElementPosition.x * this.boxSize),
              (this.floatingElementPosition.y * this.boxSize)
            )

            break;
          }

          case constants.MODES.MASS_INSERT: {
            this.floatingElement.visible = false;

            break;
          }
        }
    },
    redrawGridRowAndColNumbers(rowNumbersElement, colNumbersElement, pixiViewport) {
      rowNumbersElement.position.y = pixiViewport.top + 10;
      colNumbersElement.position.x = pixiViewport.left + 10;
    },
    redrawMassInsertRectangle(pixiViewport, currentMousePosition) {
      if(!this.massInsert.draw.isDrawing) return;

      if(this.massInsert.draw.element._geometry != null) {
        this.massInsert.draw.element.destroy() // After pointerup we destroy the object so new iteration has null geometry
        this.deletePixiElement(pixiViewport, "mass-insert-square")
      }

      this.massInsert.draw.element = new PIXI.Graphics();
      this.massInsert.draw.element.lineStyle(4, 0xFF0000);

      let deltaX = currentMousePosition.x - this.massInsert.draw.startPoint.x;
      let deltaY = currentMousePosition.y - this.massInsert.draw.startPoint.y;

      this.massInsert.draw.element.drawRect(
        this.massInsert.draw.startPoint.x,
        this.massInsert.draw.startPoint.y, 
        deltaX, 
        deltaY
      );

      this.massInsert.draw.element.name = "mass-insert-square";

      pixiViewport.addChild(this.massInsert.draw.element);
      
    },
    rotate() {
      this.currentRotation += 1;
      if (this.currentRotation > 3) {
        this.currentRotation = 0;
      }
    },
    nextStage() {
      this.screenshot();
      this.$store.commit('projectData/setConfiguration', this.getConfigurationElements())
      this.$store.commit('projectData/setProducts', this.itemList)
      router.push({ name: 'project-data' })
    },
    screenshot() {   
      function b64toBlob(b64Data, contentType, fileName) {
          contentType = contentType || '';
          let sliceSize = 512;

          var byteCharacters = atob(b64Data);
          var byteArrays = [];

          for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              var slice = byteCharacters.slice(offset, offset + sliceSize);

              var byteNumbers = new Array(slice.length);
              for (var i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
              }

              var byteArray = new Uint8Array(byteNumbers);

              byteArrays.push(byteArray);
          }

        var blob = new Blob(byteArrays, {type: contentType});
        
        var file = new File([blob], fileName, {
          lastModified: new Date(0)
        });
        return file;
      }
      
      let levelPictures = [];
      let textElements = [];

      for(let level = 0; level < 5; level++) {
        let pixiCanvas = this.pixiLevels[level];
        let localBounds = pixiCanvas.getLocalBounds();

        if (pixiCanvas.children.length == 0) {
          continue;
        }

        let tempAlpha = pixiCanvas.alpha;
        pixiCanvas.alpha = 1;

        // Append ruler to it temporary
        // X axis ruler
        let count = 0;
        for(let xPos = localBounds.x; xPos < localBounds.x + localBounds.width; xPos += this.boxSize) {
          let rowTextElement = new PIXI.Text(count + "", {
            fontFamily: "Arial",
            fontSize: 15,
            fontWeight: "bold",
            align: "center",
          });
          
          rowTextElement.anchor.set(0.5, 0);
          rowTextElement.position.set(xPos, localBounds.y + localBounds.height);
          count ++;

          pixiCanvas.addChild(rowTextElement);
          textElements.push(rowTextElement);
        }

        // Y axis ruler
        count = 0;
        for(let yPos = localBounds.y + localBounds.height; yPos > localBounds.y; yPos -= this.boxSize) {
          let columTextElement = new PIXI.Text(count + "", {
            fontFamily: "Arial",
            fontSize: 15,
            fontWeight: "bold",
            align: "center",
          });

          columTextElement.position.set(localBounds.x - 30, yPos - 22)
          count ++;

          pixiCanvas.addChild(columTextElement);
          textElements.push(columTextElement); 
        }
      
        //Create image blob
        let b64Url = this.pixiApp.renderer.plugins.extract.canvas(pixiCanvas).toDataURL();

        var block = b64Url.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var blob = b64toBlob(realData, contentType, `level.${level-2}.png`);
        levelPictures.push(blob);

        pixiCanvas.alpha = tempAlpha;

        // Remove ruler element after the image of level is done
        textElements.forEach(textElement => {
          pixiCanvas.removeChild(textElement);
        })
      }
      
      // Store levels picutre for CRM & Overview
      this.$store.commit('projectData/setPictures', levelPictures);
    },
    setupBasicShapeConfiguration(pixiParent, elementStore) {
      // Fetch calculated points and handle insert on every one
      this.$store.getters["basicData/getBasicShapeConfigurationPoints"].forEach((gridPosition) => {
        this.handleInsert(gridPosition, this.currentItem, elementStore, pixiParent)
      });

      // After its done clean vuex store, we dont need to keep the objects and hog memory
      this.$store.dispatch('basicData/cleanBasicShapeConfiguration')
    },
    getConfigurationElements() {
      const elementStoreElements = this.elementStore.getAll();
      const elements = [];
      const connectors = [];
      const texts = [];

      let allTexts = this.textStore.getAll();
      
      for (const position in allTexts) {
        for (const timestamp in allTexts[position]) {
          let textElement = allTexts[position][timestamp];
          texts.push({
            gridPosition: textElement.gridPosition,
            text: textElement.text
          })
          break;
        }
      }

      for (let [, element] of Object.entries(elementStoreElements.elements)) {
        elements.push({
          gridPosition: element.gridPosition,
          itemName: element.item.name,
          color: element.variation.color,
        })
      }

      for (let [, connector] of Object.entries(elementStoreElements.connectors)) {
        connectors.push({
          gridPosition: connector.gridPosition,
          color: connector.color,
          itemName: connector.item.name,
        });
      }
      return {
        elements,
        connectors,
        texts
      }
    }
  },
}
</script>